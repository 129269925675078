<template>
  <div class="mx-auto max-w-xl">
    <PhoneHeader />
    <main class="overscroll-y-contain">
      <div class="flex h-16 items-end justify-center">
        <div class="h-2.5 w-1/2 rounded-full bg-gray-50">
          <div
            class="h-2.5 rounded-full bg-indigo-500"
            :style="`width: ${percent}%`"
          ></div>
        </div>
      </div>
      <Transition name="slide-fade">
        <div v-if="showSpinner" class="mb-4 mt-4 max-h-10">
          <svg
            class="mx-auto h-5 w-5 animate-spin p-0 text-indigo-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </Transition>
      <div class="mb-20 mt-4 px-4 sm:px-6 lg:px-8">
        <slot />
      </div>
    </main>
    <TabBar />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const { showSpinner, needRefresh } = usePullToRefresh();

const percent = ref(0);

let startPoint = 0;
let pullChange = 0;

onMounted(() => {
  window.addEventListener("touchstart", pullStart);
  window.addEventListener("touchmove", pull);
  window.addEventListener("touchend", endPull);
});

onUnmounted(() => {
  window.removeEventListener("touchstart", pullStart);
  window.removeEventListener("touchmove", pull);
  window.removeEventListener("touchend", endPull);
});

const pull = (e: TouchEvent) => {
  /**
   * get the current user touch event data
   */
  const touch = e.targetTouches[0];
  /**
   * get the touch position on the screen's Y axis
   */
  const { screenY } = touch;
  /**
   * The length of the pull
   *
   * if the start touch position is lesser than the current touch position, calculate the difference, which gives the `pullLength`
   *
   * This tells us how much the user has pulled
   */
  const pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
  pullChange = pullLength;

  percent.value = (Math.min(pullChange, 150) * 100.0) / 150.0;
};

function pullStart(e: TouchEvent) {
  const { screenY } = e.targetTouches[0];
  startPoint = screenY;
}

const endPull = () => {
  if (pullChange > 150) {
    needRefresh.value = true;
    setTimeout(() => {
      needRefresh.value = false;
    }, 200);
  }

  startPoint = 0;
  pullChange = 0;
  percent.value = 0;
};
</script>

<style>
.slide-fade-enter-active {
  transition: opacity 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  max-height: 0;
}
</style>
